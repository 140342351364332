<template>
    <div>
        <!-- <el-card>
    <nav class="input" style="width: auto">
        <el-input
            v-model="search.name"
            size="medium"
            placeholder="商品名"
          />
      </nav>
      <nav class="input" style="width: 150px">
          <el-button size="small" @click="reset">重置</el-button>
          <el-button type="primary" size="small" @click="subimt">{{
            '搜索'
          }}</el-button>
        </nav>
      </nav>
        <nav class="input" style="width: auto">
        <el-button type="primary" size="small"  @click="add">{{
          '添加商品'
        }}</el-button>
      </nav>
    </el-card>  -->

        <el-card style="margin-top: 10px">
            <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                <el-table-column label="ID" prop="id"> </el-table-column>
                <el-table-column label="规则名称" prop="name"> </el-table-column>
                <el-table-column label="积分" prop="bonus">
                    <!-- <template slot-scope="{ row }">
                        <div v-if="row.type == 'cooperation_top'">
                            <p v-for="item in row.extendRule" :key="item.day">{{ item.day }}天：{{ item.bonus }}积分</p>
                        </div>
                        <div v-else>
                            <span>{{ row.bonus }}</span>
                        </div>
                    </template> -->
                </el-table-column>
                <el-table-column label="帆币" prop="coin"> </el-table-column>
                <el-table-column label="类型" prop="type"> </el-table-column>
                <el-table-column label="状态" prop="status">
                    <template slot-scope="{ row }">
                        <el-tag type="warning" size="small" class="box">{{ row.status == 0 ? "启用" : "禁用" }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="createTime"> </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="{ row }">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="mini"
                            v-show="$store.state.btn_if.indexOf('task_rule_edit') != -1"
                            class="skyblue"
                            @click="edit(row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            v-show="$store.state.btn_if.indexOf('task_rule_hide') != -1"
                            icon="el-icon-setting"
                            size="mini"
                            style="color:tan"
                            @click="show(row)"
                            >{{ row.status == 0 ? "禁用" : "启用 " }}</el-button
                        >
                        <!-- <el-button
              v-if="row.type == 'invitation_reg'"
              type="text"
              icon="el-icon-view"
              size="mini"
              style="color:#FF6666"
              @click="invitedRecord(row)"
              >{{ '邀请记录' }}</el-button
            > -->
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="search.page"
                background
                :page-sizes="[3, 5, 10, 15]"
                :page-size="search.pageSize"
                layout="total, prev, pager, next"
                :total="total"
                @current-change="handleCurrentChange"
            />
        </el-card>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
export default {
    mixins: [list],
    data() {
        return {
            search: {
                page: 1,
                pageSize: 20,
            },
        };
    },
    created() {
        this.getlist();
    },
    methods: {
        add() {},
        async getlist() {
            const { data: res } = await this.$http.get("/admin/TaskRule/getList?page", {
                params: {
                    ...this.search,
                },
            });
            //   console.log(res);
            this.list = res.data.list;
            this.total = res.data.totalCount;
        },
        async show(row) {
            var { data: res } = await this.$http.post("/admin/TaskRule/updateStatus", {
                id: row.id,
                status: row.status ? 0 : 1,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
        },
        invitedRecord(row) {
            this.$router.push({
                path: "/user_invitation_log",
            });
        },
        edit(row) {
            this.$router.push({
                path: "/task_rule_add",
                query: {
                    id: row.id,
                },
            });
        },
        subimt() {},
    },
};
</script>

<style lang="less" scoped></style>
